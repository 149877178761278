import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FlexWrapper } from '../../../components/global/flexbox';

export default function FundationGoals() {
  const { t } = useTranslation();
  return (
    <GoalGrid
      flex_direction="column"
      flex_align="flex-start"
      flex_items="flex-start">
      <FlexWrapper>
        <Number>1</Number>
        <FlexWrapper
          flex_align="flex-start"
          flex_items="flex-start"
          flex_direction="column">
          <h4>{t`about.goal-item-header-one`}</h4>
          <p>{t`about.goal-item-text-one`}</p>
        </FlexWrapper>
      </FlexWrapper>
      <FlexWrapper>
        <Number>2</Number>
        <FlexWrapper
          flex_align="flex-start"
          flex_items="flex-start"
          flex_direction="column">
          <h4>{t`about.goal-item-header-two`}</h4>
          <p>{t`about.goal-item-text-two`}</p>
        </FlexWrapper>
      </FlexWrapper>
      <FlexWrapper>
        <Number>3</Number>
        <FlexWrapper
          flex_align="flex-start"
          flex_items="flex-start"
          flex_direction="column">
          <h4>{t`about.goal-item-header-three`}</h4>
          <p>{t`about.goal-item-text-three`}</p>
        </FlexWrapper>
      </FlexWrapper>
      <FlexWrapper>
        <Number>4</Number>
        <FlexWrapper
          flex_align="flex-start"
          flex_items="flex-start"
          flex_direction="column">
          <h4>{t`about.goal-item-header-four`}</h4>
          <p>{t`about.goal-item-text-four`}</p>
        </FlexWrapper>
      </FlexWrapper>
      <FlexWrapper>
        <Number>5</Number>
        <FlexWrapper
          flex_align="flex-start"
          flex_items="flex-start"
          flex_direction="column">
          <h4>{t`about.goal-item-header-five`}</h4>
          <p>{t`about.goal-item-text-five`}</p>
        </FlexWrapper>
      </FlexWrapper>
    </GoalGrid>
  );
}
const Number = styled.span`
  min-width: 80px;
  height: 80px;
  box-shadow: 0px 0px 20px rgba(15, 83, 115, 0.2);
  border-radius: 50%;
  font-family: 'Montserrat';
  font-size: 48px;
  font-weight: 700;
  line-height: 59px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GoalGrid = styled(FlexWrapper)`
  width: 100%;
  margin: 0;
  > * h4 {
    font-family: 'Montserrat';
    font-size: 18px;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: -0.02em;
    padding-bottom: 0.5rem;
  }
  ${FlexWrapper} {
    margin: 0;
    padding-bottom: 2.3125rem;
    @media only screen and (min-width: 568px) and (max-width: 1123px) {
      margin: 0;
      margin-bottom: 0;
    }
    @media only screen and (max-width: 568px) {
      margin: 0;
      width: unset;
    }
    ${FlexWrapper} {
      max-width: 50%;
      padding-left: 1.25rem;
      padding-top: 0.5rem;
      @media only screen and (max-width: 1224px) {
        flex-direction: column;
      }
    }
    ${Number} {
      color: #4ebfee;
    }
    &:nth-of-type(2n) {
      ${Number} {
        color: #0f5575 !important;
      }
    }
    @media only screen and (max-width: 1224px) {
      flex-direction: row;
    }
  }
`;
