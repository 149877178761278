import React from 'react';

const Video = ({ src, title }) => (
  <div className="video-container">
    <iframe
      title={title}
      src={src}
      allowFullScreen
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    />
  </div>
);

export default Video;
