import styled from 'styled-components';
import { SectionHeader } from '../../components/typography';
import { Button } from '../../components/global/button';
import { respondTo } from '../../utils/breakpoints';


//TODO clean up here

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-position-x: 45%;
  background-size: ${(props) => props.bg_img_size || 'default'};
  ${respondTo.m`
    background-image: ${(props) => `url(${props.bg_img})` || 'none'};
    flex-direction: ${(props) => props.direction || 'row'};
    justify-content: ${(props) => props.justify || null};
    gap: ${(props) => props.container_gap || null};
    align-items: ${(props) => props.align || null};
  `}
  .mpk-video {
    width: 100%;
  }
  .white {
    ${respondTo.m`
      margin-right: 200px !important;
    `}
    ${respondTo.xs`
      margin: 0;
      height: 400px;
    `}
  }
  .blue {
    color: white;
    > p {
      color: white;
    }
    ${respondTo.m`
      margin-left: 200px !important;
    `}
    ${respondTo.xs`
      height: 308px;
      margin: 0;
    `}
    ${SectionHeader} {
      color: white !important;
      width: 100%;
    }
  }
  > div {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: ${(props) => props.theme.colors.uiDarkBlue};
    ${respondTo.xs`
      width:100%;
      &.500 {
        height: 500px;
      }
    `}
    > ${SectionHeader} {
      text-align: left !important;
      color: ${(props) => props.theme.colors.uiDarkBlue} !important;
    }
    span {
      font: ${(props) => props.theme.typography.body.m};
    }
    p {
      padding-bottom: 2rem;
    }
  }
  &.Okun {
    background-position-x: 50%;
    > div {
      ${respondTo.xs`
      height:500px;
    `}
    }
  }
`;
export const PageWrapper = styled.div`
  scroll-behavior: smooth;
`;

export const SectionHeaders = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1.5rem;
  padding: 0 1rem;
  ${respondTo.xs`
    justify-content: space-between;
    align-items: center;
    ${SectionHeader} {
      text-align: left !important;
    }
  `}
`;

export const BtnWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
  ${Button} {
    &:first-of-type {
      margin-bottom: 1.5rem;
    }
    width: 14.375rem;
  }
`;

export const AuthorsGridImg = styled.img`
  border-radius: 50%;
  border: 5px solid white;
  width: 200px;
`;
