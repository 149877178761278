import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalContext } from '../../../contexts/ModalContext';
import { SectionHeader, Text } from '../../../components/typography';
import { Button } from '../../../components/global/button';

export default function AboutSectionBlue() {
  const { t } = useTranslation();
  const { setIsDonateModalOpen } = useContext(ModalContext);
  return (
    <div className="blue">
      <SectionHeader>{t`about.header-eleven`}</SectionHeader>
      <Text>{t`about.text-nine`}</Text>

      <Button
        className="outlined-solid-white"
        style={{ width: '200px' }}
        onClick={() => setIsDonateModalOpen(true)}>
        {t('about.button-support-us')}
      </Button>
    </div>
  );
}
