import React from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import '../styles/slider.scss';
import { StaticImage } from 'gatsby-plugin-image';

export default function AboutSlider() {
  const { t } = useTranslation();
  return (
    <Slider
      className="about-us__slider"
      dots={true}
      slidesToShow={3}
      responsive={[
        {breakpoint: 1280, settings: {slidesToShow: 2, slidesToScroll: 2}},
        {breakpoint: 1024, settings: {slidesToShow: 1, slidesToScroll: 1}}
      ]}
      slidesToScroll={3}
      infinite={true}
      autoplaySpeed={4000}
      autoplay={true}>
      <div className="about-us__slider__item">
        <div className="about-us__slider__item__img">
          <StaticImage src="../dir/QI24.png"/>
        </div>
        <div className="about-us__slider__item__title">
          <h3>{t`about.award-header-eleven`}</h3>
        </div>
        <div className="about-us__slider__item__text">
          <span>{t`about.award-text-two`}</span>
        </div>
      </div>
      <div className="about-us__slider__item">
        <div className="about-us__slider__item__img">
          <StaticImage src="../dir/List Nominacyjny - Polska Nagroda Innowacyjności 2024.png"/>
        </div>
        <div className="about-us__slider__item__title">
          <h3>{t`about.award-header-three`}</h3>
        </div>
        <div className="about-us__slider__item__text">
          <span>{t`about.award-text-three`}</span>
        </div>
      </div>
      <div className="about-us__slider__item">
        <div className="about-us__slider__item__img">
          <StaticImage src="../dir/symbol23.png"/>
        </div>
        <div className="about-us__slider__item__title">
          <h3>{t`about.award-header-nine`}</h3>
        </div>
        <div className="about-us__slider__item__text">
          <span>{t`about.award-text-nine`}</span>
        </div>
      </div>
      <div className="about-us__slider__item">
        <div className="about-us__slider__item__img">
          <StaticImage src="../dir/symbol23.png"/>
        </div>
        <div className="about-us__slider__item__title">
          <h3>{t`about.award-header-eight`}</h3>
        </div>
        <div className="about-us__slider__item__text">
          <span>{t`about.award-text-eight`}</span>
        </div>
      </div>
      <div className="about-us__slider__item">
        <div className="about-us__slider__item__img">
          <StaticImage src="../dir/Ambasador_Innowacyjnosci.png"/>
        </div>
        <div className="about-us__slider__item__title">
          <h3>{t`about.ambasador_innowacyjności`}</h3>
        </div>
        <div className="about-us__slider__item__text">
          <span>{t`about.award-text-four`}</span>
        </div>
      </div>
      <div className="about-us__slider__item">
        <div className="about-us__slider__item__img">
          <StaticImage src="../dir/champion22.png"/>
        </div>
        <div className="about-us__slider__item__title">
          <h3>{t`about.award-header-seven`}</h3>
        </div>
        <div className="about-us__slider__item__text">
          <span>{t`about.award-text-seven`}</span>
        </div>
      </div>
      <div className="about-us__slider__item">
        <div className="about-us__slider__item__img">
          <StaticImage src="../dir/QI22.png"/>
        </div>
        <div className="about-us__slider__item__title">
          <h3>{t`about.award-header-two`}</h3>
        </div>
        <div className="about-us__slider__item__text">
          <span>{t`about.award-text-two`}</span>
        </div>
      </div>
      <div className="about-us__slider__item">
        <div className="about-us__slider__item__img">
          <StaticImage src="../dir/mip.png"/>
        </div>
        <div className="about-us__slider__item__title">
          <h3>{t`about.award-header-four`}</h3>
        </div>
        <div className="about-us__slider__item__text">
          <span>{t`about.award-text-four`}</span>
        </div>
      </div>
      <div className="about-us__slider__item">
        <div className="about-us__slider__item__img">
          <StaticImage src="../dir/QI21.png"/>
        </div>
        <div className="about-us__slider__item__title">
          <h3>{t`about.award-header-one`}</h3>
        </div>
        <div className="about-us__slider__item__text">
          <span>{t`about.award-text-one`}</span>
        </div>
      </div>
      <div className="about-us__slider__item">
        <div className="about-us__slider__item__img">
          <StaticImage src="../dir/pni.png"/>
        </div>
        <div className="about-us__slider__item__title">
          <h3>{t`about.award-header-ten`}</h3>
        </div>
        <div className="about-us__slider__item__text">
          <span>{t`about.award-text-three`}</span>
        </div>
      </div>
      <div className="about-us__slider__item">
        <div className="about-us__slider__item__img">
          <StaticImage src="../dir/ideas.png"/>
        </div>
        <div className="about-us__slider__item__title">
          <h3>{t`about.award-header-five`}</h3>
        </div>
        <div className="about-us__slider__item__text">
          <span>{t`about.award-text-five`}</span>
        </div>
      </div>
      <div className="about-us__slider__item">
        <div className="about-us__slider__item__img">
          <StaticImage src="../dir/struna.png"/>
        </div>
        <div className="about-us__slider__item__title">
          <h3>{t`about.award-header-six`}</h3>
        </div>
        <div className="about-us__slider__item__text">
          <span>{t`about.award-text-six`}</span>
        </div>
      </div>
    </Slider>
  );
}
