import React, { useEffect, useState } from 'react';
import Layout from '../../components/layout/layout';
import { useTranslation } from 'react-i18next';
import { Link, withPrefix } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import AboutSlider from './components/sliderComponent';
import Seo from '../../components/seo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import styled, { keyframes } from 'styled-components';
import Okun from './dir/okun.png';
import CirclesOne from './dir/circlesheaderCircles.svg';
import CirclesTwo from './dir/circles.svg';
import FoundationGoals from './components/foundationGoals';
import {
  FlexBox,
  PageSection,
  SectionWrapper
} from '../../components/global/flexbox';
import { respondTo } from '../../utils/breakpoints';
import { PageHeader, SectionHeader, Text } from '../../components/typography';
import { ThemeProvider } from 'styled-components';
import { theme } from '../../themes/default';
import { Flex } from '../news/styled.components';
import { InhubButton } from '../../components/global/buttons/';
import AboutSectionBlue from './components/sectionBlue';
import Video from '../../components/video';
import Bubbles1 from '../../images/home/theme-circles/circles-elements-our-mission.png';
import Bubbles3 from '../../images/home/theme-circles/circle-elements-left.png';
import { isWindow } from '../../components/global/utils';
import './styles/theme.css';
import InHubVideo from '../../assets/videos/InHub_blueprint.mp4';

export default function AboutPage() {
  const { t } = useTranslation();

  const [scroll, setScroll] = useState(false);

  const [isMobile, setIsMobile] = useState(
    isWindow() ?? window.innerWidth < 1115
  );

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > window.innerHeight / 5) {
        setScroll(true);
      } else {
        setScroll(false);
      }

      setIsMobile(window.innerWidth < 1115);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scroll, setScroll]);

  return (
    <Layout withHeader={false}>
      <Seo
        title={t('about-us.page-title')}
        description={t('about-us.page-desc')}
      />
      <ThemeProvider theme={theme}>
        <PageBox withHeader={false}>
          <div className="home-ctn about-fundation-hero" style={{background: 'transparent'}}>
            <div className="intro-ctn" style={{background: '#FAFAFA'}}>
              <div className="container">
                <div className="intro-header-ctn" style={{zIndex: 1}}>
                  <h1 style={{color: '#0F5575'}}>{t`about.header-one`}</h1>
                  <p style={{color: '#080E14', fontSize: '32px', fontWeight: '400', lineHeight: '150%'}}>{t`about.text-one`}</p>
                </div>
                <div className="intro-image-ctn" style={{zIndex: 0}}>
                  <div className="hero-gradient"/>
                  <video loop muted autoPlay playsInline poster="#" className="video-background" id="video-home">
                    <source src={InHubVideo} type="video/mp4" />
                  </video>
                </div>

              </div>
            </div>
          </div>
          {/*<DarkWrapper*/}
          {/*  bg_img={CirclesOne}*/}
          {/*  bg_img_size="contain"*/}
          {/*  bg_img_pos="left top">*/}
          {/*  <FlexBox>*/}
          {/*    <div>*/}
          {/*      <PageHeader>{t`about.header-one`}</PageHeader>*/}
          {/*      <Text>{t`about.text-one`}</Text>*/}
          {/*      <InhubButton*/}
          {/*        type="outlined"*/}
          {/*        to="#raport"*/}
          {/*        text={t`about.get_report`}*/}
          {/*      />*/}
          {/*    </div>*/}
          {/*    <div>*/}
          {/*      <StaticImage src="./dir/kacper.png" alt="poznaj-nas" />*/}
          {/*    </div>*/}
          {/*  </FlexBox>*/}
          {/*</DarkWrapper>*/}
          <PageSection>
            <FlexBox>
              <div className="flex-child" style={{ paddingBottom: '0' }}>
                <SectionHeader>
                  {t`about.header-two`}
                  <b> {t`about.header-two-two`} </b>
                  {t`about.header-two-three`}
                </SectionHeader>
                <Text>{t`about.text-two`}</Text>
              </div>
              <div className="flex-child">
                <StaticImage src="./dir/Group 144.png" alt="" />
              </div>
            </FlexBox>
          </PageSection>
          <PageSection>
            <FlexBox flex_direction="row-reverse">
              <div className="flex-child four-six">
                <SectionHeader>
                  {t`about.header-three`}
                  <b>{t`about.header-three-two`}</b>
                </SectionHeader>
                <Text>{t`about.text-three`}</Text>
              </div>
              <div
                className="flex-child four-six stat-ctn"
                style={{ paddingBottom: '2rem' }}>
                <Chart>
                  <div className={scroll ? 'scrolled' : null}>
                    <span>{t`about.graph-one-text`}</span>
                    <SectionHeader>60%</SectionHeader>
                  </div>
                  <div className={scroll ? 'scrolled' : null}>
                    <span>{t`about.graph-two-text`}</span>
                    <SectionHeader>43%</SectionHeader>
                  </div>
                  <div className={scroll ? 'scrolled' : null}>
                    <span>{t`about.graph-three-text`}</span>
                    <SectionHeader>40%</SectionHeader>
                  </div>
                  <div className={scroll ? 'scrolled' : null}>
                    <span>{t`about.graph-four-text`}</span>
                    <SectionHeader>26%</SectionHeader>
                  </div>
                  <div className={scroll ? 'scrolled' : null}>
                    <span>{t`about.graph-five-text`}</span>
                    <SectionHeader>19%</SectionHeader>
                  </div>
                </Chart>
              </div>
            </FlexBox>
          </PageSection>
          <DarkWrapper
            bg_img={CirclesTwo}
            bg_img_size="contain"
            bg_img_pos="left bottom">
            <FlexBox flex_direction="column">
              <SectionHeader>{t`about.header-four`}</SectionHeader>
              <Text>{t`about.text-four`}</Text>
              <InhubButton
                type="outlined"
                to="/career"
                text={t`about.button-join-us`}
                icon={faArrowRight}
              />
              <div className="about-us__section-image">
                <StaticImage
                  src="./dir/teamThird_ver2.png"
                  alt="Zespół Fundacji"
                />
              </div>
            </FlexBox>
          </DarkWrapper>
          <PageSection align_text="center">
            <FlexBox flex_direction="column">
              <h5>{t`about.award-header-how`}</h5>
              <SectionHeader>
                {t`about.header-five`}
                <b>{t`about.header-five-two`}</b>
              </SectionHeader>
              <Text>{t`about.text-five`}</Text>
              <GridBox>
                <Card>
                  <StaticImage
                    src="../../images/logos/inhub/mentoring_dark.png"
                    alt="Logo MedBiz Innovations Program"
                  />
                  <div>
                    <h3>{t`about-us.why-to-become-1-title`}</h3>
                    <Text>{t`about-us.why-to-become-1-text`}</Text>
                  </div>
                  <Pointer to="/mentoring-program">
                    {t`about.button-more-mip`}
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Pointer>
                </Card>
                <Card>
                  <StaticImage
                    src="../../images/logos/inhub/incubator_dark.png"
                    alt="Logo Innovations Incubator"
                  />
                  <div>
                    <h3>{t`about-us.why-to-become-2-title`}</h3>
                    <Text>{t`about-us.why-to-become-2-text`}</Text>
                  </div>
                  <Pointer to="/incubator">
                    {t`about.button-more-ii`}
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Pointer>
                </Card>
                <Card>

                  <StaticImage
                    src="../../images/logos/inhub/Akademia_Przyszlosci_Logo_ciemne.png"
                    alt="Akademia Przyszłości - logo"
                  />
                  <div>
                    <h3>{t`future-academy.academy_title`}</h3>
                    <Text>{t`future-academy.fah-title`}</Text>
                  </div>
                  <Pointer to="/future-academy">
                    {t`about.button-more-ip`}
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Pointer>
                </Card>
                <Card>
                  <StaticImage
                    src="../../images/logos/inhub/academy_dark.png"
                    alt="Logo Innovations Academy"
                  />
                  <div>
                    <h3>{t`about-us.why-to-become-4-title`}</h3>
                    <Text>{t`about-us.why-to-become-4-text`}</Text>
                  </div>
                  <Pointer to="/news">
                    {t`about.button-more-ia`}
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Pointer>
                </Card>
                <Card>
                  <StaticImage
                    src="../../images/logos/inhub/ami_dark.png"
                    alt="Logo Akademi Młodego Innowatora"
                  />
                  <div>
                    <h3>{t`about-us.why-to-become-5-title`}</h3>
                    <Text>{t`about-us.why-to-become-5-text`}</Text>
                  </div>
                  <Pointer to="/news/workshops">
                    {t`about.button-more-ami`}
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Pointer>
                </Card>
                {/* <Card>
                  <StaticImage
                    src="../../images/logos/inhub/gic_dark.png"
                    alt="Logo Green Innovations Challenge"
                  />
                  <div>
                    <h3>{t`about-us.why-to-become-6-title`}</h3>
                    <Text>{t`about-us.why-to-become-6-text`}</Text>
                  </div>
                  <Pointer to="/green-innovations-challenge">
                    {t`about.button-more-gic`}
                    <FontAwesomeIcon icon={faArrowRight} />
                  </Pointer>
                </Card> */}
              </GridBox>
            </FlexBox>
          </PageSection>
          <PageSection
            bg_img_size="200px"
            bg_img_pos="center right"
            bg_img_size_mobile="20%">
            <div
              className="theme-about-us-right"
              style={{
                background: `url(${Bubbles1})`,
                position: 'absolute',
                width: '200px',
                height: '300px',
                backgroundPosition: 'center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                right: 0,
                top: '300px'
              }}
            />
            <FlexBox flex_direction="column">
              <h5
                style={{
                  textAlign: 'center'
                }}>{t`about.award-header-goals`}</h5>
              <FlexBox flex_align="flex-start">
                <div
                  className="flex-child thirty"
                  flex="flex"
                  flex_align="flex-start"
                  flex_justify="start">
                  <SectionHeader>
                    {t`about.header-six`}
                    <b>{t`about.header-six-two`}</b>
                  </SectionHeader>
                </div>
                <FoundationGoals />
              </FlexBox>
            </FlexBox>
          </PageSection>
          <PageSection id="raport" bg_img={Bubbles3} bg_img_pos="top left">
            <FlexBox flex_direction="row-reverse" gap="4rem">
              <div className="flex-child" style={{ paddingBottom: 0 }}>
                <SectionHeader>
                  {t`about.header-seven`}
                  <b> {t`about.header-seven-two`}</b>
                </SectionHeader>
                <Text>{t`about.text-six`}</Text>
                <InhubButton
                  type="solid"
                  to={withPrefix('Działaność Fundacji 2021-2023.pdf')}
                  text={t`about.download_report`}
                  icon={faArrowRight}
                />
              </div>
              <div className="flex-child">
                <Video
                  src="https://www.youtube.com/embed/XapXrO_dCVo"
                  title="Odkryj działalność Fundacji"
                />
              </div>
            </FlexBox>
          </PageSection>
          <div style={{ marginTop: '8rem' }} />
          <DarkWrapper>
            <FlexBox flex_direction="column">
              <SectionHeader>{t`about.header-eight`}</SectionHeader>
              <Text>{t`about.text-seven`}</Text>
              <InhubButton
                type="outlined"
                to="/team"
                text={t`about.button-get-to-know-team`}
                icon={faArrowRight}
              />
              <div className="about-us__section-image">
                <StaticImage src="./dir/Group 161.png" alt="" />
              </div>
            </FlexBox>
          </DarkWrapper>
          <PageSection>
            <FlexBox flex_direction="column">
              <SectionHeader>{t`about.award-title`}</SectionHeader>
              <div className="about-us">
                <AboutSlider />
              </div>
            </FlexBox>
          </PageSection>
          <PageSection className="two-tone Okun">
            <SectionWrapper>
              <Flex className="Okun" bg_img={Okun}>
                <div className="white">
                  <SectionHeader>{t`about.header-ten`}</SectionHeader>
                  <Text>{t`about.text-eight`}</Text>
                  <BtnWrapper>
                    <InhubButton
                      type="solid"
                      to="/partners#become-a-partner"
                      text={t`about.button-become-partner`}
                    />
                    <InhubButton
                      type="hollow"
                      to="/partners"
                      text={t`about.button-more-info`}
                    />
                  </BtnWrapper>
                </div>
                <AboutSectionBlue />
              </Flex>
            </SectionWrapper>
          </PageSection>
        </PageBox>
      </ThemeProvider>
    </Layout>
  );
}

const PageBox = styled.div`
  font-family: 'Raleway';
  background-color: ${(props) => props.theme.background};
  margin-top: ${
    (props) => (!props.withHeader ? '112px' : '0') /*navigation height*/
  };
`;

const DarkWrapper = styled(PageSection)`
  padding-top: 4rem;
  max-width: 100vw;
  height: auto;
  background-color: rgb(15, 85, 117);
  color: white;
  margin-top: 7.5rem;
  &:nth-of-type(1) {
    margin-top: 0;
  }
  ${respondTo.m`
    margin-top: 0;
    padding: 3rem 0 0 0 !important;
  `}
  &:first-of-type {
    > * h1 {
      font-size: 48px;
      font-weight: 700;
      letter-spacing: -0.02rem;
      line-height: 59px;
      color: white;
      margin-bottom: 1rem;
    }
    > * p {
      text-align: left;
      color: white;
      font-size: 32px;
      line-height: 150%;
      letter-spacing: -0.02rem;
      font-weight: 400;
    }
  }
  > * ${SectionHeader} {
    color: white !important;
  }
  > * p {
    text-align: center;
    color: white;
    padding-bottom: 2rem;
  }
`;

const GridBox = styled.div`
  padding-top: 5rem;
  display: grid;
  grid-template-columns: 100%;
  gap: 1.25rem 0;
  ${respondTo.m`
    grid-template-columns: 50% 50%;
    grid-row: auto auto;
  `}
`;

const BtnWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start !important;
  ${InhubButton} {
    &:first-of-type {
      margin-bottom: 1.5rem;
    }
    width: 14.375rem;
  }
`;

const Card = styled.div`
  height: calc(314px - 4rem);
  width: calc(100% - 8rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border: 0.3125rem solid #4ebfee;
  border-radius: 16px;
  padding: 2rem 2rem;
  margin: auto;

  > * img {
    max-height: 3.75rem;
    @media only screen and (max-width: 568px) {
      max-height: 3.75rem;
      width: 12.5rem;
    }
  }

  > div {
    p {
      padding: 0 4rem;
      text-align: center;
      @media only screen and (max-width: 568px) {
        padding: 0 2rem 0.5rem;
      }
    }

    h3 {
      text-align: center;
      padding-bottom: 0.5rem;
      @media only screen and (max-width: 568px) {
        padding-top: 1rem;
      }
    }
  }

  @media only screen and (max-width: 568px) {
    height: auto;
  }
`;

const Animate = keyframes`
  from {
    width: 0;
    opacity: 0;
  }
  to {
    opacity: 1 !important;
    width: ${(props) => props.width};
  }
`;

const Chart = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  > div {
    width: calc(100% - 32px);
    height: 4.75rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    margin: 1rem 0;
    border-radius: 5px;
    opacity: 0;
    &.scrolled {
      opacity: 1;
      span {
        opacity: 1;
        animation: ${Animate} 1847ms step-end;
      }
    }
    span {
      font-size: 16px;
      font-weight: 700;
      line-height: 150%;
      opacity: 0;
      padding-right: 0.625rem;
    }
    > * {
      color: white !important;
    }
    &:first-of-type {
      width: 558px;
      background-color: #0f5575;
      &.scrolled {
        animation: ${Animate} 1200ms linear;
      }
    }
    &:nth-of-type(2) {
      width: 544px;
      background-color: #4ebfee;
      &.scrolled {
        animation: ${Animate} 1323.63236ms linear;
      }
    }
    &:nth-of-type(3) {
      width: 514px;
      background-color: #4ebfee;
      &.scrolled {
        animation: ${Animate} 1323.63236ms linear;
      }
    }
    &:nth-last-of-type(2) {
      width: 400px;
      background-color: #f5fafd;
      > * {
        color: #0f5575 !important;
      }
      &.scrolled {
        animation: ${Animate} 1523ms linear;
      }
    }
    &:nth-last-of-type(1) {
      width: 300px;
      background-color: #f5fafd;
      &.scrolled {
        animation: ${Animate} 1847ms linear;
      }
      > * {
        color: #0f5575 !important;
      }
    }
  }
  @media only screen and (max-width: 568px) {
    > div {
      width: 82vw !important;
      &:nth-of-type(2) {
        width: 80vw !important;
        background-color: #4ebfee;
      }
      &:nth-of-type(3) {
        width: 76vw !important;
        background-color: #4ebfee;
      }
      &:nth-last-of-type(2) {
        width: 58vw !important;

        background-color: #f5fafd;
      }
      &:nth-last-of-type(1) {
        width: 50vw !important;

        background-color: #f5fafd;
      }
    }
  }
`;

const Pointer = styled(Link)`
  svg {
    padding-left: 0.625rem;
  }
`;
